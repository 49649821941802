//utility function to retrieve page language
export const checkPageLangContext = (lang) => {
  switch (lang) {
    case "en":
      return "en";
    case "ar":
      return "ar";
    default:
      return "en";
  }
};

//utility function to retrieve page type
export const checkPageTypeContext = (page) => {
  if (page === "" || page === "/") {
    return "Home Page";
  } else if (page.includes("experience-collection")) {
    return "Visit Page";
  } else if (page.includes("dining")) {
    return "Dining Page";
  } else if (page.includes("contact")) {
    return "ContactUs Page ";
  } else if (page.includes("retrieve-booking")) {
    return "Retrieve Booking Page ";
  } else if (page.includes("retrieve-booking")) {
    return "Retrieve Booking Page ";
  } else if (page.includes("tour-booking")) {
    return "Booking Page";
  } else if (page.includes("booking-confirmation")) {
    return "Booking Confirmation Page";
  } else {
    console.log(page);
    return "Page not Found";
  }
};

export const pageLoad = (data) => {
  let d = data.sitecore.route;
  let dataLayer =
    data && data.sitecore && data.sitecore.route && data.sitecore.route.fields
      ? data.sitecore.route.fields
      : false;
  let pageNameEnglish =
    dataLayer && dataLayer["Page Title"] && dataLayer["Page Title"]
      ? dataLayer["Page Title"].value
      : "";
  let pageType = window.location.pathname;
  let dataLayerObject = {
    event: "pageLoad", // Static
    pageLoad: {
      pageLanguage: d.itemLanguage, // en, ar, de, ru, zh Language of the page
      pageType: checkPageTypeContext(pageType.slice(3)), // Change based on viewed page type extracted from CMS
      pageNameEnglish: pageNameEnglish, //Change based on viewed page. The value should be always English
    },
  };
  window.dataLayer && window.dataLayer.push(dataLayerObject);
};
export const pageLoadBooking = (data, pageType) => {
  let pageNameEnglish =
    data &&
    data.fields &&
    data.fields["Page Title"] &&
    data.fields["Page Title"].value;
  let dataLayerObject = {
    event: "pageLoad", // Static
    pageLoad: {
      pageLanguage: data.itemLanguage, // en, ar, de, ru, zh Language of the page
      pageType: pageType, // Change based on viewed page type extracted from CMS
      pageNameEnglish: pageNameEnglish, //Change based on viewed page. The value should be always English
    },
  };
  console.log(dataLayerObject, "pageLoadBooking");
  window.dataLayer.push(dataLayerObject);
};

//dataLayer push when the booking flow is initiated
export const productdetail = (
  pageData,
  path_context,
  page_cta_context
) => {
    let pageType = window.location.pathname;

    let ecommProducts = [];

    console.log(pageData, "pageData");

    let priceforCard = "";

    let bookingCategoryList = pageData.fields["Booking Category List"][0].fields["CategoryList"];

    console.log(pageData.fields["Booking Category List"][0].fields["Category"].value, "Category");

    bookingCategoryList.forEach((eachadltDetails) => {

        //console.log(eachadltDetails);

        if (eachadltDetails.fields.Family.length > 0) {
            priceforCard = eachadltDetails.fields["Family"][0].fields["Price"].value;
        } else {
            priceforCard = eachadltDetails.fields["Adult"][0].fields["Price"].value;
        }

        ecommProducts.push({
            id: eachadltDetails.id,
            name: eachadltDetails.fields['Title'].value,
            price: priceforCard,
            brand: "IBAA",
            category: pageData.fields["Booking Category List"][0].fields["Category"].value !== "" ? pageData.fields["Booking Category List"][0].fields["Category"].value : "Tours" ,
            list: "product list",
            position: 1
        });        

    });

  let dataLayerObject = {
    event: "ecomm_productdetail",
    pageLanguage: pageData.itemLanguage, //self explanatory that this needs to be the language of the page viewed by the user
    initPageTypeContext: checkPageTypeContext(pageType.slice(3)), //name of the page type from where the booking flow was initiated
      initPageCtaContext: page_cta_context, //CTA context that was used to initiate the booking flow
      ecommerce: {
          journey: pageData.displayName,
          detail: {
              actionField: { list: "Product list", action: "productdetail" },
              products: ecommProducts
          }
      }
    //  (top right cta, hero cta, etc..  ). This should also be defined with the Business Analyst
    };

   console.log(dataLayerObject, "ecomm_productdetail");
   window.dataLayer.push(dataLayerObject);
};

//dataLayer push when availability results are returned from VGS
export const availabilityCheckResult = (
  path_context,
  page_cta_context,
  pageData,
  guestInfo,
  dateofVisit,
  timeofVisit,
  timeSlots,
  selectedTourType
) => {
  let pathContext = path_context;
  let CTA_type = page_cta_context;
  let pageType = window.location.pathname;
  let dataLayerObject = {
    event: "availabilityCheckResult",
    pageLanguage: pageData.itemLanguage, //self explanatory that this needs to be the language of the page viewed by the user
    initPageTypeContext: checkPageTypeContext(pageType.slice(3)), //name of the page type from
    //where the booking flow was initiated
    initPageCtaContext: CTA_type,
    //CTA context that was used to initiate the booking flow (top right cta, hero cta, etc..  ). This should also be defined with the Business Analyst
    guideLanguage: "Not Available", //language of the tour guide for which availability has been checked
    adultCount: guestInfo.Adult, //number of adults availability is checked for
    childCount: guestInfo.Child, //number of children availability is checked for
    infantCount: guestInfo.Infant, //number of infants availability is checked for
    searchedArrivalDate: dateofVisit, //searched arrival date in YYYY-MM-DD format
    searchedArrivalTime: timeofVisit, // searched arrival time in HH:MM (24hours) format
    availabilityCheckResultExperienceName: selectedTourType.name, // searched Tour Name
    availabilityCheckResultExperienceType: selectedTourType.type, // searched Tour type
    availabilityCheckResultExperienceCode: selectedTourType.productCode, // searched Tour Code
    availabilityResults: {
      countOfAvailableExperiences: timeSlots, //number of experiences available on availability results (integer)
    },
  };

  console.log(dataLayerObject, "availabilityCheckResult");
  sessionStorage.setItem(
    "availabilityCheckResult",
    JSON.stringify(dataLayerObject)
  );
  window.dataLayer.push(dataLayerObject);
};

export const promotionview = (props) => {
   // console.log(props);
    let dataLayerObject = {
        event: "ecomm_promotionview",
        ecommerce: {
            promoView: {
                promotions: [
                    {
                        id: props.pageData.templateId,
                        name: props.fields["Visit Hero Title"].value,
                        creative: props.fields["Hero Image"].value.src,
                        position:"1"
                    }
                ]
            }
        }
    }
    console.log(dataLayerObject, "promotionview");
    window.dataLayer.push(dataLayerObject);
};

export const promotionclick = (props,ishome) => {

    console.log(ishome,'ishome');
    console.log(props,'promotionclick')

    let ecommPromotions = [];

    if (ishome) {
        ecommPromotions.push({
            id: props.id,
            name: props.fields["Title"].value,
            creative: props.fields["Background Image"].value.src,
            position: "1"
        });
    }
    else {
        ecommPromotions.push({
            id: props.fields["Title"].value,
            name: props.fields["Visit Hero Title"].value,
            creative: props.fields["Hero Image"].value.src,
            position: "1"
        });
    }
  
    let dataLayerObject = {
        event: "ecomm_promotionclick",
        ecommerce: {
            promoClick: {
                promotions: ecommPromotions
            }
        }
    }

    console.log(dataLayerObject, "promotionclick");
    window.dataLayer.push(dataLayerObject);
};

export const productimpression = (path_context, page_cta_context, pageData, fields) => {

    //console.log(path_context);
    //console.log(page_cta_context);
    //console.log(pageData);
    //console.log(fields);

    //console.log("productimpression");

    let ecommProducts = [];

    fields["Tour Category List"].forEach((eachCard, idx) => {

        //console.log(eachCard);

        ecommProducts.push({
            id: eachCard.id,
            name: eachCard.fields["Title"].value,
            price: 'NA',
            brand: "IBAA",
            category: eachCard.fields["Title"].value,
            position: idx
        });

    });

    let dataLayerObject = {
        event: "ecomm_productimpression",
        ecommerce: {
            journey: pageData.displayName,
            currencyCode: "AED",
            actionField: { list: "Product list", action: "impressions" },
            impressions: ecommProducts
        }
    }

    console.log(dataLayerObject, "productimpression");
    //sessionStorage.setItem("paymentRedirect", JSON.stringify(dataLayerObject));
    window.dataLayer.push(dataLayerObject);
};

export const productclick = (details) => {

    //console.log('productclick');
    //console.log(details);
    //console.log('tourType');
    //console.log(tourType);

    let ecommProducts = [];

    ecommProducts.push({
        id: details.id,
        name: details.fields["Title"].value,
        price: 'NA',
        brand: "IBAA",
        category: details.fields["Title"].value,
        position: 1
    });
    
    let dataLayerObject = {
        event: "ecomm_productclick",
        ecommerce: {
            journey: details.displayName,
            click: {
                actionField: { list: "Product list", action: "click" },
                products: ecommProducts                 
            }
        }
    }

    console.log(dataLayerObject, "productclick");

    window.dataLayer.push(dataLayerObject);
};

export const addToCartProduct = (details, tourType) => {

    //console.log('addtocart');
    //console.log(details);
    //console.log(price);
    //console.log(tourType);
    let priceforCard = "";

    if (details.fields["Family"].length > 0) {
        priceforCard = details.fields["Family"][0].fields["Price"].value;
    } else {
        priceforCard = details.fields["Adult"][0].fields["Price"].value;
    }

    let ecommProducts = [];

    if (details.fields["Family"].length > 0) {
        ecommProducts.push({
            id: details.id,
            name: details.fields["Title"].value,
            price: priceforCard,
            brand: "IBAA",
            category: tourType,
            position: "NA",
            numberOfAdults: 2,
            numberOfChildren: 2,
            quantity: 1
        });
    }
    else {
        ecommProducts.push({
            id: details.id,
            name: details.fields["Title"].value,
            price: priceforCard,
            brand: "IBAA",
            category: tourType,
            position: "NA",
            numberOfAdults: 1,
            numberOfChildren: 0,
            quantity: 1
        });
    }

    let dataLayerObject = {
        event: "ecomm_addtocart",
        ecommerce: {
            journey: tourType,
            currencyCode: "AED",
            add: {
                actionField: { list: "Product list", action: "add" },
                products: ecommProducts
            }
        }        
    }
    console.log(dataLayerObject, "addtocart");
    //console.log(dataLayerObject, "productclick");
    //sessionStorage.setItem("paymentRedirect", JSON.stringify(dataLayerObject));
    window.dataLayer.push(dataLayerObject);
};

export const addtocartDataLayer = (guestInfo, eachGuest,selectedTourType, dateofVisit, timeofVisit) => {

    //console.log(guestInfo, "guestInfo");
    //console.log(eachGuest, "eachGuest");
    console.log(selectedTourType, "selectedTourType");
    //console.log(dateofVisit, "dateofVisit");
    //console.log(timeofVisit, "timeofVisit");

    let ecommProducts = [];

    if (selectedTourType.productId.Family != null) {
        ecommProducts.push({
            id: selectedTourType.productCode,
            name: selectedTourType.name,
            price: parseInt(selectedTourType.cost.amount) + parseInt(eachGuest.price),
            brand: "IBAA",
            bookingDate: dateofVisit,
            bookingTime: timeofVisit,
            numberOfAdults: 2,
            numberOfChildren: 2,
            quantity: 1,
            category: selectedTourType.type,
            position: 1
        });
    }
    else {
        if (eachGuest.type === "Adult") {
            ecommProducts.push({
                id: selectedTourType.productCode,
                name: selectedTourType.name,
                price:  parseInt(eachGuest.price),
                brand: "IBAA",
                bookingDate: dateofVisit,
                bookingTime: timeofVisit,
                numberOfAdults:  1 ,
                numberOfChildren: 0,
                quantity: 1,
                category: selectedTourType.type,
                position: "NA"
            });
        }
        else if (eachGuest.type === "Child") {
            ecommProducts.push({
                id: selectedTourType.productCode,
                name: selectedTourType.name,
                price:  parseInt(eachGuest.price),
                brand: "IBAA",
                bookingDate: dateofVisit,
                bookingTime: timeofVisit,
                numberOfAdults: 0 ,
                numberOfChildren: 1,
                quantity: 1,
                category: selectedTourType.type,
                position: "NA"
            });
        }
        
    }

   
    let dataLayerObject = {
        event: "ecomm_addtocart",
        ecommerce: {
            journey: selectedTourType.type,
            currencyCode: "AED",
            add: {
                actionField: { list: "Product list", action: "add" },
                products: ecommProducts
            }
        }
    }
    console.log(dataLayerObject, "addtocart");
    window.dataLayer.push(dataLayerObject);
};

export const addtocartExtras = (guestInfo, cartData, selectedTourType, dateofVisit, timeofVisit) => {

     //console.log(guestInfo, "guestInfo");
     //console.log(cartData, "cartData");
     //console.log(selectedTourType, "selectedTourType");
     //console.log(dateofVisit, "dateofVisit");
     //console.log(timeofVisit, "timeofVisit");

    let ecommProducts = [];
    let idx = 0;
    cartData.items.forEach((productItem) => {
        idx = idx + 1;
        ecommProducts.push({
            id: productItem.ShopCartItemId,
            name: productItem.ProductName,
            price: productItem.TotalAmount,
            brand: "IBAA",
            bookingDate: dateofVisit,
            bookingTime: timeofVisit,
            numberOfAdults: "",
            numberOfChildren: "",
            quantity: productItem.Quantity,
            category: selectedTourType.type,
            list: "Product list",
            position: idx
        });
    });

    let dataLayerObject = {
        event: "ecomm_addtocart",
        currencyCode: "AED",
        add: {
            products: ecommProducts
        }
    }
    console.log(dataLayerObject, "addtocartextras");
    //sessionStorage.setItem("paymentRedirect", JSON.stringify(dataLayerObject));
    window.dataLayer.push(dataLayerObject);
};

export const removefromcart = (guestInfo, eachGuest, selectedTourType, dateofVisit, timeofVisit) => {

   // console.log(eachGuest, "eachGuest");
   // console.log(guestInfo, "guestInfo");
   // console.log(selectedTourType, "selectedTourType");
   // console.log(dateofVisit, dateofVisit)
   // console.log(timeofVisit, timeofVisit)

    let ecommProducts = [];

    if (selectedTourType.productId.Family != null) {
        ecommProducts.push({
            id: selectedTourType.productCode,
            name: selectedTourType.name,
            price: parseInt(selectedTourType.cost.amount) - parseInt(eachGuest.price),
            brand: "IBAA",
            bookingDate: dateofVisit,
            bookingTime: timeofVisit,
            numberOfAdults: "2",
            numberOfChildren: "2",
            quantity: 1,
            category: selectedTourType.type,
            list: "Product list",
            position: "NA"
        });
    }
    else {
        ecommProducts.push({
            id: selectedTourType.productCode,
            name: selectedTourType.name,
            price: parseInt(selectedTourType.cost.amount) - parseInt(eachGuest.price),
            brand: "IBAA",
            bookingDate: dateofVisit,
            bookingTime: timeofVisit,
            numberOfAdults: eachGuest.type === "Adult" ? guestInfo.Adult - 1 : guestInfo.Adult,
            numberOfChildren: eachGuest.type === "Child" ? guestInfo.Child - 1 : guestInfo.Child,
            quantity: 1,
            category: selectedTourType.type,
            list: "Product list",
            position: "NA"
        });
    }

    //if (eachGuest.type === "Adult") {
    //    ecommProducts.push({
    //        id: selectedTourType.productId.Adult,
    //        name: selectedTourType.name + " Adult",
    //        price: selectedTourType.price.Adult,
    //        brand: "IBAA",
    //        bookingDate: dateofVisit,
    //        bookingTime: timeofVisit,
    //        numberOfAdults: guestInfo.Adult,
    //        numberOfChildren: guestInfo.Child,
    //        quantity: 1,
    //        category: selectedTourType.type,
    //        list: "product list",
    //        position: 1
    //    });
    //}    

    //if (selectedTourType.productId.Child != null && eachGuest.type === "Child") {
    //    ecommProducts.push({
    //        id: selectedTourType.productId.Child,
    //        name: selectedTourType.name + " Child",
    //        price: selectedTourType.price.Child,
    //        brand: "IBAA",
    //        bookingDate: dateofVisit,
    //        bookingTime: timeofVisit,
    //        numberOfAdults: guestInfo.Adult,
    //        numberOfChildren: guestInfo.Child,
    //        quantity: 1,
    //        category: selectedTourType.type,
    //        list: "product list",
    //        position: 2
    //    });    
    //}    

    let dataLayerObject = {
        event: "ecomm_removefromcart",
        remove: {
            products: ecommProducts
        }
    }
    console.log(dataLayerObject, "removefromcart");
    //sessionStorage.setItem("paymentRedirect", JSON.stringify(dataLayerObject));
    window.dataLayer.push(dataLayerObject);
};

export const removefromcartExtras = (guestInfo, eachGuest, selectedTourType, dateofVisit, timeofVisit) => {

    //console.log(eachGuest, "eachGuest");
    //console.log(guestInfo, "guestInfo");
    //console.log(selectedTourType, "selectedTourType");
    //console.log(dateofVisit, dateofVisit)
    //console.log(timeofVisit, timeofVisit)

    let ecommProducts = [];

    if (selectedTourType.productId.Family != null) {
        ecommProducts.push({
            id: selectedTourType.productId.Family,
            name: selectedTourType.name + " Family",
            price: selectedTourType.price.Family,
            brand: "IBAA",
            bookingDate: dateofVisit,
            bookingTime: timeofVisit,
            numberOfAdults: guestInfo.Adult,
            numberOfChildren: guestInfo.Child,
            quantity: 1,
            category: selectedTourType.type,
            list: "Product list",
            position: 1
        });
    }

    if (eachGuest.type === "Adult") {
        ecommProducts.push({
            id: selectedTourType.productId.Adult,
            name: selectedTourType.name + " Adult",
            price: selectedTourType.price.Adult,
            brand: "IBAA",
            bookingDate: dateofVisit,
            bookingTime: timeofVisit,
            numberOfAdults: guestInfo.Adult,
            numberOfChildren: guestInfo.Child,
            quantity: 1,
            category: selectedTourType.type,
            list: "Product list",
            position: 1
        });
    }

    if (selectedTourType.productId.Child != null && eachGuest.type === "Child") {
        ecommProducts.push({
            id: selectedTourType.productId.Child,
            name: selectedTourType.name + " Child",
            price: selectedTourType.price.Child,
            brand: "IBAA",
            bookingDate: dateofVisit,
            bookingTime: timeofVisit,
            numberOfAdults: guestInfo.Adult,
            numberOfChildren: guestInfo.Child,
            quantity: 1,
            category: selectedTourType.type,
            list: "Product list",
            position: 2
        });
    }

    let dataLayerObject = {
        event: "ecomm_removefromcart",
        remove: {
            products: ecommProducts
        }
    }
    console.log(dataLayerObject, "removefromcart");
    //sessionStorage.setItem("paymentRedirect", JSON.stringify(dataLayerObject));
    window.dataLayer.push(dataLayerObject);
};

export const onCheckout = (guestInfo, selectedTourType, step, dateofVisit, timeofVisit) => {

    //console.log(guestInfo, "guestInfo");
    //console.log(selectedTourType, "selectedTourType");
    //console.log(dateofVisit, 'dateofVisit');
    //console.log(timeofVisit, 'timeofVisit');

    let ecommProducts = [];
        
    ecommProducts.push({
        id: selectedTourType.productCode,
        name: selectedTourType.name,
        price: guestInfo === "" ? selectedTourType.cost.amount : ((guestInfo.Adult * selectedTourType.price.Adult) + (guestInfo.Child * selectedTourType.price.Child)),
        brand: "IBAA",
        bookingDate: dateofVisit,
        bookingTime: timeofVisit === "" ? "10:30" : timeofVisit,
        numberOfAdults: guestInfo === "" ? "1" :  guestInfo.Adult,
        numberOfChildren: guestInfo === "" ? "0" : guestInfo.Child,
        quantity: 1,
        category: selectedTourType.type,
        position: "NA"
    });

    

    let dataLayerObject = {
        event: "ecomm_checkoutsteps",
        ecommerce: {
            journey: selectedTourType.name,
            checkout: {
            actionField: {
                list: "Product list",
                action: "checkout",
                step: step,
                option: ""
            },
            products: ecommProducts
           }
        }
    }
    console.log(dataLayerObject, "checkoutsteps");
    //sessionStorage.setItem("paymentRedirect", JSON.stringify(dataLayerObject));
    window.dataLayer.push(dataLayerObject);
};

export const finalCheckout = (guestInfo, selectedTourType, step, dateofVisit, timeofVisit, cartData, addOnsData) => {

    //console.log(guestInfo, "guestInfo");
    //console.log(selectedTourType, "selectedTourType");
    //console.log(dateofVisit, 'dateofVisit');

//    console.log(cartData, 'cartData');
 //   console.log(addOnsData, 'addOnsData');


    let ecommProducts = [];

    let idx = 0;

    ecommProducts.push({
        id: selectedTourType.productCode,
        name: selectedTourType.name,
        price: guestInfo === "" ? selectedTourType.cost.amount : ((guestInfo.Adult * selectedTourType.price.Adult) + (guestInfo.Child * selectedTourType.price.Child)),
        brand: "IBAA",
        bookingDate: dateofVisit,
        bookingTime: timeofVisit === "" ? "10:30" : timeofVisit,
        numberOfAdults: guestInfo === "" ? "1" : guestInfo.Adult,
        numberOfChildren: guestInfo === "" ? "0" : guestInfo.Child,
        quantity: 1,
        category: selectedTourType.type,
        position: idx
    });

    //addOnProducts = cartData.items.filter((data) => addOnsData.some((p) => p.title.toLowerCase().includes(data.ProductName.toLowerCase())));

    //console.log(addOnProducts, "addOnProducts");

    cartData.items.forEach((productItem) => {
        //addOnProducts = addOnsData.find((data) => data.title === productItem.ProductName);  
        //console.log(addOnProducts);
        if (!productItem.ProductName.toLowerCase().includes('adult') &&
            !productItem.ProductName.toLowerCase().includes('family') && 
            !productItem.ProductName.toLowerCase().includes('child'))
        {
            idx = idx + 1;
            ecommProducts.push({
                id: productItem.ShopCartItemId,
                name: productItem.ProductName,
                price: productItem.TotalAmount,
                brand: "IBAA",
                bookingDate: dateofVisit,
                bookingTime: timeofVisit,
                numberOfAdults: "",
                numberOfChildren: "",
                quantity: productItem.Quantity,
                category: selectedTourType.type,
                position: idx
            });
        }
    });

    let dataLayerObject = {
        event: "ecomm_checkoutsteps",
        ecommerce: {
            journey: selectedTourType.name,
            checkout: {
                actionField: {
                    step: step,
                    list: "Product list",
                    action: "checkout",
                    option: ""
                },
                products: ecommProducts
            }
        }
    }

    console.log(dataLayerObject, "checkoutsteps");
    //sessionStorage.setItem("paymentRedirect", JSON.stringify(dataLayerObject));
    window.dataLayer.push(dataLayerObject);
};

//dataLayer push when a valid payment attempt is made from the final checkout step
export const redirectToPaymentGateway = (data) => {

    let cartData = data.cartBase;
    let selectedTourType = data.selectedTourType;
    let guestInfo = data.guestInfo;
    let dateofVisit = data.dateofVisit;
    let timeofVisit = data.timeofVisit;
    let ecommProducts = [];
    
    ecommProducts.push({
        id: cartData.shopCartId,
        name: selectedTourType.name,
        price: cartData.totalAmount,
        brand: "IBAA",
        bookingDate: dateofVisit,
        bookingTime: timeofVisit,
        numberOfAdults: guestInfo.Adult,
        numberOfChildren: guestInfo.Child,
        quantity: 1,
        category: selectedTourType.type,
        position: "NA"
    });

    let dataLayerObject = {
        event: "ecomm_paymentsuccess",
        currencyCode: "AED",
        purchase: {
            actionField: {
                list: "Product list"
            },
            products: ecommProducts
        }
    }

    console.log(data, "paymentRedirect");
    sessionStorage.setItem("paymentRedirect", JSON.stringify(data));
    window.dataLayer.push(dataLayerObject);
};

//dataLayer push on booking confirmation page whoch will be seen after successful payment
export const purchaseComplete = (bookingConfirmationID) => {
    let paymentRedirect = JSON.parse(sessionStorage.getItem("paymentRedirect"));

    console.log(paymentRedirect);

    if (paymentRedirect) {

        let cartData = paymentRedirect.cartBase;
        let selectedTourType = paymentRedirect.selectedTourType;
        let guestInfo = paymentRedirect.guestInfo;
        let dateofVisit = paymentRedirect.dateofVisit;
        let timeofVisit = paymentRedirect.timeofVisit;
        let ecommProducts = [];

        //console.log(cartData, 'cartData');
        //console.log(guestInfo, 'guestInfo');
        //console.log(dateofVisit, 'dateofVisit');
        //console.log(timeofVisit, 'timeofVisit');
        //console.log(selectedTourType, 'selectedTourType');

        let idx = 0;

        ecommProducts.push({
            id: cartData.shopCartId,
            name: selectedTourType.name,
            price: cartData.totalAmount,
            brand: "IBAA",
            bookingDate: dateofVisit,
            bookingTime: timeofVisit,
            numberOfAdults: guestInfo.Adult,
            numberOfChildren: guestInfo.Child,
            quantity: 1,
            category: selectedTourType.type,
            position: "NA"
        });

        cartData.items.forEach((productItem) => {
            if (!productItem.ProductName.toLowerCase().includes('adult') &&
                !productItem.ProductName.toLowerCase().includes('family') && 
                !productItem.ProductName.toLowerCase().includes('child'))
            {
                idx = idx + 1;
                ecommProducts.push({
                    id: productItem.ShopCartItemId,
                    name: productItem.ProductName,
                    price: productItem.TotalAmount,
                    brand: "IBAA",
                    bookingDate: dateofVisit,
                    bookingTime: timeofVisit,
                    numberOfAdults: "",
                    numberOfChildren: "",
                    quantity: productItem.Quantity,
                    category: selectedTourType.type,
                    position: idx
                });
            }            
        });

        let dataLayerObject = {            
            event: "ecomm_purchasesuccess",
            ecommerce: {
                journey: selectedTourType.name,
                currencyCode: "AED",
                purchase: {
                    actionField: {
                        id : bookingConfirmationID,                         // Transaction ID. Required for purchases and refunds.
                        affiliation: 'IBAA B2C',
                        revenue : cartData.totalAmount,                     // Total transaction value (incl. tax and shipping)          
                        list: "Product list",
                        bookingDate : dateofVisit,
                        bookingTime: timeofVisit,
                        coupon: ''
                    },
                    products: ecommProducts
                }
            }
        }
        console.log(dataLayerObject, "purchaseComplete");
        window.dataLayer.push(dataLayerObject);
        sessionStorage.clear();
    }
};

export const addtoCartFailure = (
    dateofVisit,
    guestInfo,
    timeofVisit,
    selectedTourType
) => {
    let dataLayerObject = {
        event: "addtoCartFailure",
        addtoCartFailureAdultCount: guestInfo.Adult, //number of adults availability is checked for
        addtoCartFailureChildCount: guestInfo.Child, //number of children availability is checked for
        addtoCartFailureInfantCount: guestInfo.Infant, //number of infants availability is checked for
        addtoCartFailureSearchedArrivalDate: dateofVisit, //searched arrival date in YYYY-MM-DD format
        addtoCartFailureSearchedArrivalTime: timeofVisit, // searched arrival time
        addtoCartFailureExperienceName: selectedTourType.name, // searched Tour Name
        addtoCartFailureExperienceType: selectedTourType.type, // searched Tour type
        addtoCartFailureExperienceCode: selectedTourType.productCode, // searched Tour Code
    };

    console.log(dataLayerObject, "addtoCartFailure");

    window.dataLayer.push(dataLayerObject);
};

export const sendStayGuestInfoToGTM = (stayGuest) => {
  let dataLayerObject = {
    event: "stayGuestInfo",
    stayGuestInfoLastName: stayGuest.lastName.value, //number of adults availability is checked for
    stayGuestInfoBookingID: stayGuest.bookingNumber.value, //number of children availability is checked for
    stayGuestInfoHotel: stayGuest.hotelName.value, //number of infants availability is checked for
  };
  console.log(dataLayerObject, "stayGuestInfo");
  window.dataLayer.push(dataLayerObject);
};

export const couponAppliedToGTM = ({
  ccode,
  discountApplied,
  tourTypeName,
}) => {
  let dataLayerObject = {
    event: "couponApplied",
    couponAppliedCode: ccode, //number of adults availability is checked for
    couponAppliedDiscount: discountApplied, //number of children availability is checked for
    couponAppliedExperienceName: tourTypeName, //number of infants availability is checked for
  };
  console.log(dataLayerObject, "stayGuestInfo");
  window.dataLayer.push(dataLayerObject);
};
