import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";
import { PRODUCTCODE_LIST, PRODUCTCODE_TO_PRODUCTNAME } from "../constants";

let restService = new RestService();

export const addtoCartService = async ({
  cartBase,
  addOns,
  addOnsData,
  performanceId,
}) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "ADDTOCART"
  ).endpoint;

  let addOnToCart = addOnsData.find((each) => each.productCode === addOns.type);
  console.log(addOnToCart, "addOnToCartaddOnToCartaddOnToCart");

  let itemToReplace = [];
  if (addOns.type === PRODUCTCODE_LIST.kidsClub) {
    itemToReplace = [...cartBase.items].filter((each) =>
      each.ProductName.includes("Child") && !each.ProductName.includes("Kids Club")
    );
  }

  let request = {
    requestType: "addToCart",
    data: {
      type: serviceType,
      requestParam: {
        shopCartId: cartBase.shopCartId,
        items: itemToReplace,
        productLists: [
          {
            productId: addOnToCart.productId,
            productName:addOnToCart.title,
            productNameExt:addOnToCart.title,
            requestedGuestCount: 1,
            performanceId: performanceId,
            displayPrice: addOnToCart.price,
          },
        ],
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let cartData = {};

        if (res.status === 200 && res.data.data) {
          cartData = {
            shopCartId: res.data.data.shopCartId,
            subTotal: res.data.data.subTotal,
            totalTax: res.data.data.totaltax,
            totalAmount: res.data.data.totalAmount,
            items: res.data.data.items,
          };
          resolve(cartData);
        } else {
          throw "Error adding to cart ";
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const editCartService = async ({
  cartBase,
  addOns,
  addOnsData,
  performanceId,
  selectedTourType,
}) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "REMOVETOCART"
  ).endpoint;

  let itemsToRemove = [...cartBase.items].filter((item) =>
    item.ProductName.toLowerCase().includes(
      PRODUCTCODE_TO_PRODUCTNAME[addOns.type].toLowerCase()
    )
  );

  let productList = [];
  if (addOns.type === PRODUCTCODE_LIST.kidsClub) {
    productList.push({
      productId: selectedTourType.productId.Child,
      productName: "Child Tour",
      productNameExt: "Child Tour",
      requestedGuestCount: 1,
      performanceId: performanceId,
      displayPrice: selectedTourType.price.Child,
    });
  }

  let request = {
    requestType: "editItemQuantity",
    data: {
      type: serviceType,
      requestParam: {
        shopCartId: cartBase.shopCartId,
        items: itemsToRemove,
        productLists: productList,
      },
    },
  };
  console.log(request, "editcartrequest");
  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let editCartData = {};
        console.log(res);
        if (res.status === 200 && res.data.data) {
          editCartData = {
            shopCartId: res.data.data.shopCartId,
            subTotal: res.data.data.subTotal,
            totalTax: res.data.data.totaltax,
            totalAmount: res.data.data.totalAmount,
            items: res.data.data.items,
          };
          resolve(editCartData);
        } else {
          throw "error editing cart";
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
