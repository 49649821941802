import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

export const couponCodeService = async (payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "COUPONTOCART"
  ).endpoint;

  let request = {
    requestType: "couponToCart",
    data: {
      type: serviceType,
      requestParam: {
        couponCode: payload.couponCode,
        shopCartId: payload.shopCartId,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        console.log(res);
        let couponData = {};
        if (
          res.status === 200 &&
          res.data.data.couponMessage === "Coupon Appiled"
        ) {
          couponData = {
            shopCartId: res.data.data.shopCartId,
            totalDiscount: res.data.data.totalDiscount,
            totalTax: res.data.data.totaltax,
            totalAmount: res.data.data.totalAmount,
          };
          resolve(couponData);
        } else {
          throw "Coupon cannot be applied";
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
