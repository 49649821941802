import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

//we dont use addons Products but shopcart of base producst already selected is required from this API

export const addOnsService = async (payload) => {
 
  let serviceType = RestConfig.find(
    (data) => data.type === "AVAILABLEADDONPRODUCTS"
  ).endpoint;

  let request = {
    requestType: "availableAddonProducts",
    data: {
      type: serviceType,
      requestParam: {
        familyTourCount: payload.guestInfo.Family,
        adultCount: payload.isPackage.selected ? payload.additionalGuestInfo.Adult : payload.guestInfo.Adult,
        childCount: payload.isPackage.selected ? payload.additionalGuestInfo.Child : payload.guestInfo.Child,
        infantCount: payload.isPackage.selected ? payload.additionalGuestInfo.Infant : payload.guestInfo.Infant,
        performanceId: payload.performanceId,
        productId: payload.productId,
        saleChannelCode: payload.isJumeirahGuest ? "JUMHTL" : null,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let addOnsData = {};
       
        if (res.status === 200 && res.data.data) {
          addOnsData = {
            baseProducts: res.data.data[0].baseProducts,
            adultPrice: res.data.data[0].adultPrice,
            childPrice: res.data.data[0].childPrice,
            productList: res.data.data[0].productList,
            familyPrice: res.data.data[0].familyPackagePrice,
            shopCartId: res.data.data[0].shopCartId,
            subTotal: res.data.data[0].subTotal,
            totalTax: res.data.data[0].totaltax,
            totalAmount: res.data.data[0].totalAmount,
            items: res.data.data[0].items,
          };
          resolve(addOnsData);
        } else {
          throw (res, "error in addons");
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
