import React from "react";
import Helmet from "react-helmet";
import { withRouter } from "react-router-dom";
import fav from "../../../assets/favicon.ico";
import OpenGraphTag from "../OpenGraphTag";

function getCanonicalURL(location, lang = "en", isHreflangSEO = false) {
  let canonicalUrl = location.pathname || "/";
  canonicalUrl =
    /^\/[A-Za-z]{2}$/.test(canonicalUrl) ||
    /^\/[A-Za-z]{2}\/.*$/.test(canonicalUrl)
      ? isHreflangSEO
        ? `/${lang}${canonicalUrl.substring(3)}`
        : canonicalUrl
      : canonicalUrl.lastIndexOf("/") === 0 ||
        canonicalUrl.lastIndexOf("/") === -1
      ? `/${lang}`
      : `/${lang}/` + canonicalUrl.slice(1);
  canonicalUrl =
    canonicalUrl.lastIndexOf("/") === canonicalUrl.length - 1
      ? canonicalUrl.slice(0, canonicalUrl.length - 1)
      : canonicalUrl;
  canonicalUrl = (window.location.origin + canonicalUrl).toLowerCase();
  return canonicalUrl;
}

function PageMetadata(props) {
  const { route, location } = props;
  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        {/* <link rel="shortcut icon" href={fav} /> */}
        <html lang={route.itemLanguage} />
        {/* <link rel="shortcut icon" href={fav} /> */}
        <title>
          {(route.fields &&
            route.fields["Browser Title"] &&
            route.fields["Browser Title"].value) ||
            "Burj Al Arab"}
        </title>
        <meta
          name="description"
          content={
            (route.fields &&
              route.fields["Meta Description"] &&
              route.fields["Meta Description"].value) ||
            ""
          }
        />
        <meta
          name="keywords"
          content={
            (route.fields &&
              route.fields["Meta Keywords"] &&
              route.fields["Meta Keywords"].value) ||
            ""
          }
        />        
        {
          <meta
            name="robots"
            content={
              route.fields &&
              route.fields["Seo Follow Links"] &&
              route.fields["Seo Follow Links"].value === false
                ? "noindex, nofollow"
                : ""
            }
          />
        }
        {<meta http-equiv="content-language" content={route.itemLanguage} />}
        {route.fields &&
          route.fields["Show in SEO Sitemap"] &&
          route.fields["Show in SEO Sitemap"].value && (
            <link rel="canonical" href={getCanonicalURL(location)} />
          )}
        {(
          (route &&
            route.placeholders &&
            route.placeholders.Header &&
            route.placeholders.Header[0] &&
            route.placeholders.Header[0].fields &&
            route.placeholders.Header[0].fields.Language) ||
          []
        ).map((lang, index) => {
          const languageCode =
            lang.fields &&
            lang.fields["Language Code"] &&
            lang.fields["Language Code"].value;
          return (
            <link
              key={index}
              rel="alternate"
              hreflang={`${languageCode}`}
              href={getCanonicalURL(location, languageCode, true)}
            />
          );
        })            
      }
        <link  rel="alternate"
            hreflang="x-default"
            href={getCanonicalURL(location, "en", true)}
        />
        <OpenGraphTag image={route.fields["Image"]} title={route.fields["Browser Title"]}
                  description={route.fields["Meta Description"]} location={location}></OpenGraphTag>

        <script type="application/ld+json"> 
          {(route.fields &&
              route.fields["Page Schema"] &&
              route.fields["Page Schema"].value)}
        </script>
      </Helmet>
    </React.Fragment>
  );
}

export default withRouter(PageMetadata);
