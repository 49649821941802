import * as Action from "./bookingDetailsActions.js";

const initialState = {
  selectedTourType: {
    type: "",
    name: "",
    imageSrc: "",
    allowed: { Adult: true, Child: true, Infant: true },
    applicableTimeSlots: "All",
    cost: {
      currencyCode: "",
      amount: "",
    },
  },
  pageLanguage: "en",
  isJumeirahGuest: false,
  isPackage: {
    selected: false,
    additionalGuestInfo: { Adult: 0, Child: 0, Infant: 0 },
  },
  cartBase: {
    baseProducts: [],
    shopCartId: "",
    performanceId: "",
    items: [],
    subTotal: "",
    totalTax: "",
    totalAmount: "",
    totalDiscount: "",
  },
  couponCode: "",
  guestInfo: { Family: 0, Adult: 1, Child: 0, Infant: 0 },
  availability: [],
  timeofVisit: "",
  dateofVisit: "",
  performanceId: "",
  mainGuestDetails: {},
  giftGuestDetails: {},
  pnrInfo: {
    saleCode: "",
    paymentStatus: false,
    saleId: "",
    transactionId: "",
    pahDownloadUrl:"",
  },
  isAinDubai: false,
  isPartner: false,
  addOnsData: [],
  kidsClub: { type: "Child", timeSlot: "" },
  outletReference: "",
  toastmessage: {
    showToast: false,
    header: "",
    description: "",
  },
  addOnServiceErr:false,
};

export const bookingDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Action.UPDATE_BOOKINGDETAILS_BYKEY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case Action.RESET_BOOKINGDETAILS_BYKEY: {
      return {
        ...state,
        [action.payload]: { ...initialState[action.payload] },
      };
    }
    default:
      return state;
  }
};
