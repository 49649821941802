import axios from 'axios'
export const BOOKING_STEP = "BOOKING_STEP";
export const AVAILABLE_PRODUCTS = "AVAILABLE_PRODUCTS";
export const SELECTED_TOURNAME = "SELECTED_TOURNAME";
export const ADULTS_NUM = "ADULTS_NUM";
export const FAMILY_NUM = "FAMILY_NUM";
export const CHILDREN_NUM = "CHILDREN_NUM";
export const INFANTS_NUM = "INFANTS_NUM";
export const TIME = "TIME";
export const DATE = "DATE";
export const ADDONID = "ADDONID";
export const PRICE = "PRICE";
export const SUBTOTAL = "SUBTOTAL"
export const PRODUCT_LIST = "PRODUCT_LIST";
export const ACCOUNT_DETAILS = "ACCOUNT_DETAILS";
export const COUPONTOCART = "COUPONTOCART";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS";
export const CONFIRMATION_NUMBER = "CONFIRMATION_NUMBER";
export const POST_TRANSACTION = "POST_TRANSACTION";
export const PNR_DELETION = "PNR_DELETION"
export const BEFORE_POST_TRANSACTION = "BEFORE_POST_TRANSACTION";
export const RETRIEVE_BOOKING = "RETRIEVE_BOOKING";
export const RESET_BOOKING = "RESET_BOOKING";
export const ACCOUNT_FORM = "ACCOUNT_FORM";
export const BILLING_FORM = "BILLING_FORM";
export const NI_ORDER_STATUS = "NI_ORDER_STATUS";
export const SALE_ID = "SALE_ID";
export const TAX = "TAX";
export const ADDITIONAL_VISITORS = "ADDITIONAL_VISITORS";
export const SLOT_DETAILS = "SLOT_DETAILS";
export const ADULTS_FORM = "ADULTS_FORM";
export const CHILDREN_FORM = "CHILDREN_FORM";
export const FAMILY_FORM = "FAMILY_FORM"
export const BOOKING_SITECORE = "BOOKING_SITECORE";
export const ENHANCEMENT = "ENHANCEMENT";
export const ADULT_ADDON = "ADULT_ADDON";
export const CHILD_ADDON = "CHILD_ADDON";
export const DUBAI_ADDON = "DUBAI_ADDON";
export const FAMILY_ADDON = "FAMILY_ADDON"
export const ADDON_PRICE = "ADDON_PRICE";
export const CHILD_ENHANCEMENT = "CHILD_ENHANCEMENT";
export const ALL_PRICE = "ALL_PRICE";
export const COUPON_TOTAL = "COUPON_TOTAL";
export const ADDON_SELECT = "ADDON_SELECT";
export const CHILD_SELECT = "CHILD_SELECT";
export const DUBAI_SELECT = "DUBAI_SELECT";
export const COCKTAIL_SELECT = "COCKTAIL_SELECT";
export const FAMILY_SELECT = "FAMILY_SELECT";
export const RESET_ADDON = "RESET_ADDON";
export const SHOPCART_ID = "SHOPCART_ID";
export const GET_ADDONS = "GET_ADDONS";
export const DISPLAY = "DISPLAY"
export const EXPERIENCE_ADDON = "EXPERIENCE_ADDON"
export const GET_PRICES = "GET_PRICES"

export const updateUserData = (user) => {
    return {
        type: UPDATE_USER_DATA,
        user
    }
}

export const updatePaymentStatus = (payment) => {
    return {
        type: UPDATE_PAYMENT_STATUS,
        payment
    }
}



export const getCountryCode = () => async (dispatch) => {
    return await axios.get('https://ipapi.co/json/')
}

export const resetAddon = () => {
    return {
        type: RESET_ADDON
    }
}
