import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

export const availabilityService = async (payload) => {
  let serviceType = RestConfig.find((data) => data.type === "AVAILABLEPRODUCTS")
    .endpoint;

  let request = {
    requestType: "availableProducts",
    data: {
      type: serviceType,
      requestParam: {
        adults: payload.guestInfo.Adult,
        children: payload.guestInfo.Child,
        infants: payload.guestInfo.Infant,
        familyPackage: payload.guestInfo.Family,
        userSelectedDate: payload.dateofVisit,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
       
        let availabiliyData = [];
        if (res.status === 200 && res.data.data[0]) {
          res.data.data[0].performanceLists.map((eachPerformace) => {
            availabiliyData.push({
              performanceId: eachPerformace.performanceId,
              timeSlot: eachPerformace.timeFrom,
              quantityFree: eachPerformace.quantityFree,
            });
          });
        }else{
          throw (res, "error in availability call");
        }
        resolve(availabiliyData);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
