import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

export const cardPaymentService = async (
    payload
) => {
    let serviceType = RestConfig.find(
        (data) => data.type === "CARDPAYMENT"
    ).endpoint;

    console.log("Selected Payment Method ", payload.paymentMethod);

    let request = {
        requestType: "cardPayment",
        data: {
            type: serviceType,
            outletId: payload.outletReference,
            sessionId: payload.sessionId,
            data: {
                outletReference: payload.outletReference,
                orderPayload: {
                    action: "SALE",
                    emailAddress: payload.mainGuestDetails.email.value,
                    language: "en",
                    merchantAttributes: {
                        redirectUrl:
                            window.location.origin.replace("localhost", "127.0.0.1") +
                            `/${window.location.pathname.substring(
                                1,
                                3
                            )}/tour-booking/booking-confirmation`,
                        skipConfirmationPage: true,
                    },
                    billingAddress: {
                        firstName: payload.mainGuestDetails.firstName.value,
                        lastName: payload.mainGuestDetails.lastName.value,
                    },
                    merchantDefinedData: {
                        email: payload.mainGuestDetails.email.value,
                        shopCartId: payload.cartBase.shopCartId,
                        saleId:"",
                    },
                },
            },
        },
    };

    return new Promise(async (resolve, reject) => {
        await restService
            .onePostService(request)
            .then(async (res) => {
                console.log("responce Order", res);
                let paymentData = {};

                if (
                    res.status === 200 &&
                    res.data.data.reference &&
                    res.data.data.state != "FAILED"
                ) {
                    console.log("nw res", res);

                    if (res.data.data.state == "AWAIT_3DS") {
                        document.getElementById("3ds_iframe").style.display = null;
                        document.getElementById("root").style.display = "none";
                    }

                    //3D CARDS
                    window.NI.handlePaymentResponse(res.data.data, {
                        mountId: "3ds_iframe",
                        style: {
                            width: "100vw",
                            height: "100vh",
                            overflow: "scroll",
                        },
                    })
                        .then(function (response) {
                            var status = response.status;

                            if (
                                status === window.NI.paymentStates.AUTHORISED ||
                                status === window.NI.paymentStates.CAPTURED
                            ) {
                                // Same as before this signals successful payment
                                paymentData = {
                                    // paymentURL: res.data.data._links.payment.href,
                                    ref: res.data.data.orderReference,
                                    outletId: res.data.data.outletId,
                                };
                                window.open(
                                    window.location.origin +
                                    `/${window.location.pathname.substring(
                                        1,
                                        3
                                    )}/tour-booking/booking-confirmation`,
                                    "_self"
                                );
                                resolve(paymentData);
                            } else if (
                                status === window.NI.paymentStates.FAILED || // A new state to look out for is 3DS Challenge failure
                                status === window.NI.paymentStates.THREE_DS_FAILURE
                            ) {
                                // payment failure signal
                                document.getElementById("root").style.display = null;
                                paymentData = {
                                    // paymentURL: res.data.data._links.payment.href,
                                    ref: res.data.data.orderReference,
                                    outletId: res.data.data.outletId,
                                };
                                console.log("paymentData", paymentData);
                                // alert("PAYMENT FAILED");
                                // window.location.reload();
                                // return true;
                                reject(paymentData);
                                // throw "error creating payment";
                            }
                        })
                        .catch((err) => {
                            // alert("Error while processing Your Payment");
                            // window.location.reload();
                            // throw "error creating payment";
                            reject(err);
                        });
                } else {
                    document.getElementById("root").style.display = null;
                    // alert("Error while processing Your Payment");
                    // window.location.reload();
                    throw "error creating payment";
                }
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
};

export const paymentService = async (
    payload
) => {
    let serviceType = RestConfig.find(
        (data) => data.type === "NIPAYMENT"
    ).endpoint;

    console.log("Selected Payment Method ", payload.paymentMethod);

    let request = {
        requestType: "niPayments",
        data: {
            type: serviceType,
            data: {
                outletReference: payload.outletReference,
                orderPayload: {
                    action: "SALE",
                    amount: {
                        currencyCode: "AED",
                        value: payload.cartBase.totalAmount * 100,
                    },
                    // payment_method: payload.paymentMethod,
                    emailAddress: payload.mainGuestDetails.email.value,
                    language: "en",
                    merchantAttributes: {
                        redirectUrl:
                            window.location.origin.replace("localhost", "127.0.0.1") +
                            `/${window.location.pathname.substring(
                                1,
                                3
                            )}/tour-booking/booking-confirmation`,
                        skipConfirmationPage: true,
                    },
                    billingAddress: {
                        firstName: payload.mainGuestDetails.firstName.value,
                        lastName: payload.mainGuestDetails.lastName.value,
                    },
                    merchantDefinedData: {
                        email: payload.mainGuestDetails.email.value,
                        shopCartId: payload.cartBase.shopCartId,
                        saleCode:  "",
                        saleId: "",
                    },
                },
            },
        },
    };

    return new Promise(async (resolve, reject) => {
        await restService
            .onePostService(request)
            .then((res) => {
                console.log("responce Order", res);
                let paymentData = {};

                if (res.status === 200 && res.data.data.reference) {
                    console.log("nw res", res);
                    paymentData = {
                        paymentURL: res.data.data._links.payment.href,
                        ref: res.data.data.reference,
                        applePaypaymentURL:
                            res.data.data._embedded.payment[0]._links["payment:apple_pay"]
                                .href,
                        // samsungPaypaymentURL: res.data.data._embedded.payment[0]._links["payment:samsung_pay"].href,
                        paymentRef: res.data.data._embedded.payment[0].reference,
                        wallet_token: res.data.wallet_token,
                        payment_amount: res.data.data.amount.value / 100,
                        outletId: res.data.data.outletId,
                    };
                    resolve(paymentData);
                } else {
                    reject(paymentData);
                    // throw "error creating payment";
                }
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
};

export const samsungPayService = async (payload) => {
    let serviceType = RestConfig.find(
        (data) => data.type === "SAMSUNGPAY"
    ).endpoint;

    console.log("samsungPay console ", payload);

    let request = {
        requestType: "samsungpay",
        data: {
            type: serviceType,
            data: payload,
        },
    };

    return new Promise(async (resolve, reject) => {
        await restService
            .onePostService(request)
            .then((res) => {
                console.log("responce Order", res);
                // let paymentData = {};
                if (res.status === 200 && res.data.status != 500) {
                    console.log("sumsung res", res);
                    resolve(res);
                } else {
                    reject(res);
                    // throw "error creating payment";
                }
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
};

export const paymentStatusService = async (payload) => {
    let serviceType = RestConfig.find(
        (data) => data.type === "PAYMENTSTATUS"
    ).endpoint;

    let request = {
        requestType: "getNiPaymentStatus",
        data: {
            type: serviceType,
            requestParam: {
                ref: payload.paymentInfo.ref,
                outletReference: payload.outletReference,
            },
        },
    };

    return new Promise(async (resolve, reject) => {
        await restService
            .onePostService(request)
            .then((res) => {
                let paymentStatusData = {};
                if (res.status === 200 && res.data.data) {
                    let paymentStatus = res.data.data._embedded.payment.find(
                        (item) => item.orderReference === payload.paymentInfo.ref
                    );

                    if (paymentStatus.state !== "FAILED") {
                        paymentStatusData = {
                            state: paymentStatus.state,
                            paymentStatus: paymentStatus,
                            paymentAmount: paymentStatus.amount.value / 100,
                            authorizationCode: paymentStatus.authResponse.authorizationCode,
                            cardholderName: paymentStatus["paymentMethod"].cardholderName,
                            cardType:
                                paymentStatus["paymentMethod"].name === "APPLE_PAY" ||
                                    paymentStatus["paymentMethod"].name === "SAMSUNG_PAY"
                                    ? paymentStatus["paymentMethod"].cardScheme
                                    : paymentStatus["paymentMethod"].name,
                            cardNumber: paymentStatus["paymentMethod"].pan,
                        };

                        resolve(paymentStatusData);
                    } else {
                        throw { status: 500, state: "FAILED" };
                    }
                } else {
                    throw { status: 500, state: "FAILED" };
                }
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
};
