import React from 'react';
import Helmet from "react-helmet";
import { withRouter } from 'react-router-dom';

function getCanonicalURL(location, lang = "en", isHreflangSEO = false) {
  let canonicalUrl = location.pathname || "/";
  canonicalUrl =
    /^\/[A-Za-z]{2}$/.test(canonicalUrl) ||
      /^\/[A-Za-z]{2}\/.*$/.test(canonicalUrl)
      ? isHreflangSEO
        ? `/${lang}${canonicalUrl.substring(3)}`
        : canonicalUrl
      : canonicalUrl.lastIndexOf("/") === 0 ||
        canonicalUrl.lastIndexOf("/") === -1
        ? `/${lang}`
        : `/${lang}/` + canonicalUrl.slice(1);
  canonicalUrl =
    canonicalUrl.lastIndexOf("/") === canonicalUrl.length - 1
      ? canonicalUrl.slice(0, canonicalUrl.length - 1)
      : canonicalUrl;
  canonicalUrl = (window.location.origin + canonicalUrl).toLowerCase();
  return canonicalUrl;
}

const OpenGraphTag = ({ image, title, description, location }) => {
  return (
    <Helmet>
      <meta property="og:image" itemProp="image" content={image} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={getCanonicalURL(location)} />>
            <meta property="og:type" content="website" />
      <meta name="twitter:site" content="@inburjalarb" /> {/* content attribute needs to be updated to IBAA twitter handle */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}

export default withRouter(OpenGraphTag);
