import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

export const pdfService = async (payload) => {
  let serviceType = RestConfig.find((data) => data.type === "GETPDF").endpoint;

  let request = {
    requestType: "getPDF",
    data: {
      type: serviceType,
      requestParam: {
        downloadUrl: payload.downloadUrl,
      },
    },
  };
  console.log("pdfService called", request);
  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        console.log(res, "pdf");
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(res.data.data.data).buffer], {
            type: "application/pdf",
          })
        );
        let link = document.createElement("a");

        link.href = url;
        link.download = "Inside Burj Al Arab Tour - Print@Home Tickets";

        link.click();
        resolve("pdf creation successful");
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
