import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

export const pnrBeforeNIService = async (payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "BEFORETRANSACTION"
  ).endpoint;

  let request = {
    requestType: "PNRNumberBeforeNI",
    data: {
      type: serviceType,
      requestParam: {
        shopCartId: payload.cartBase.shopCartId,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let pnrData = {};
        console.log(res);
        if (res.status === 200 && res.data.data.SaleCode.length > 0) {
          pnrData = {
            saleCode: res.data.data.SaleCode,
            paymentStatus: false,
            saleId: res.data.data.SaleId,
            transactionId: res.data.data.TransactionId,
          };
          resolve(pnrData);
        } else {
          throw "Pnr not created ";
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const pnrAfterNIService = async (paymentStatus, payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "TRANSACTION"
  ).endpoint;

    console.log("paymentStatus - orderReference", paymentStatus.paymentStatus.orderReference)

  let request = {
    requestType: "PNRNumber",
    data: {
      type: serviceType,
      requestParam: {
        emailAddress: payload.mainGuestDetails.email.value,
        guestEmailId: payload.mainGuestDetails.isGift
          ? payload.giftGuestDetails.email.value
          : "",
        shopCartId: payload.cartBase.shopCartId,
        paymentAmount: paymentStatus.paymentAmount,
        isCouponApplied:
          payload.cartBase.totalDiscount &&
          Number(payload.cartBase.totalDiscount > 0)
            ? true
            : false,
        saleCode: payload.pnrInfo.saleCode,
        message: payload.mainGuestDetails.isJumeirahTransfer
          ? payload.mainGuestDetails.isJumeirahTransfer
          : null,
        guestType: payload.isJumeirahGuest ? "Jumeirah" : null,
        authorizationCode: paymentStatus.authorizationCode,
        cardHolderName: paymentStatus.cardHolderName,
        cardType: paymentStatus.cardType,
        cardNumber: paymentStatus.cardNumber,
        tourType: payload.ainDubai ? "Ain Dubai" : null, //if cart has ain dubai package
        paymentOrderReferenceNumber: paymentStatus.paymentStatus.orderReference
      },
    },
  };
  console.log("reeeeeeeeeeqqqqqqqqq", request);

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let pnrAfterData = {};
        if (res.status === 200) {
          pnrAfterData = {
            saleCode: res.data.data.SaleCode,
            saleId: res.data.data.SaleId,
            pahDownloadUrl: res.data.data.pahRelativeDownloadUrl,
          };
        }
        //remove jumeirah guestType from sessionStorage after confirmation
        sessionStorage.removeItem("guestType");
        sessionStorage.removeItem("isJumeirahGuestValidated");
        resolve(pnrAfterData);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const pnrDeleteService = async () => {
  let serviceType = RestConfig.find(
    (data) => data.type === "DELETEPNR"
  ).endpoint;
  let request = {
    requestType: "PNRNumberDeletion",
    data: {
      type: serviceType,
      requestParam: {
        saleId: "",
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let pnrData = {};
        console.log(res);
        if (res.status === 200) {
          pnrData = {};
        }
        resolve(pnrData);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
