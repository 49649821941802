import axios from "axios";

const serviceList = [
  {
    type: "getStackla",
    url: "/ibaaService/stackla"
  },
  {
    type: "availableProducts",
    url: "/ibaaService/availableProducts"
  },
  {
    type: "niPayments",
    url: "/ibaaService/nipayment"
  },
  {
    type: "cardPayment",
    url: "/ibaaService/cardPayment"
  },
  {
    type: "samsungpay",
    url: "/ibaaService/samsungpay"
  },
  {
    type: "postAccount",
    url: "/ibaaService/account"
  },
  {
    type: "availableAddonProducts",
    url: "/ibaaService/availableAddonProducts"
  },
  {
    type: "couponToCart",
    url: "/ibaaService/couponToCart"
  },
  {
    type: "editItemQuantity",
    url: "/ibaaService/editItemQuantity"
  },

  {
    type: "addToCart",
    url: "/ibaaService/addToCart"
  },
  {
    type: "editItemQuantity",
    url: "/ibaaService/editItemQuantity"
  },
  {
    type: "getNiPaymentStatus",
    url: "/ibaaService/getNiPaymentStatus"
  },
  {
    type: "contactUs",
    url: "/ibaaService/sendContactEmail"
  },
  {
    type: "schoolcontactUs",
    url: "/ibaaService/sendSchoolContactEmail"
  },
  {
     type: "eventcontactUs",
      url: "/ibaaService/sendEventsContactEmail"
  },
  {
    type: "PNRNumber",
    url: "/ibaaService/PNRNumber"
  },
  {
    type: "PNRNumberDeletion",
    url: "/ibaaService/PNRNumberDeletion"
  },
  {
    type: "addOnProducts",
    url: "/ibaaService/addOnProducts"
  },
  {
    type: "PNRNumberBeforeNI",
    url: "/ibaaService/PNRNumberBeforeNI"
  },
  {
    type: "PNRNumberBeforeNI",
    url: "/ibaaService/PNRNumberBeforeNI"
  },
  {
    type: "retrieveBooking",
    url: "/ibaaService/bookingDetails"
  },
  {
    type: "confirmationMail",
    url: "/ibaaService/confirmationMail"
  },
  {
    type: "addOnPrice",
    url: "/ibaaService/addOnProducts"
  },
  {
    type: "validateReservation",
    url: "/ibaaService/validateReservation"
  },
  {
    type: "login",
    url: "/ibaaService/login"
  },
  {
    type: "getPDF",
    url: "/ibaaService/getPDF"
  },
  {
    type: "getPass",
    url: "/ibaaService/getPass"
  },
  {
    type: "sendbookingunsuccessful",
    url: "/ibaaService/sendbookingunsuccessful"
  }

];

const serviceURL = (requestType) => {
  let Path;
  let serviceType = serviceList.filter(
    (service) => service.type === requestType
  );
  console.log(serviceType)
  if (process.env["NODE_ENV"] === "development") {
    Path = process.env.REACT_APP_DEVELOPMENT_PATH + serviceType[0].url;
  } else {
    Path = serviceType[0].url;
  }
  return Path;
};

export default class restService {
  async onePostService(param) {
    let servicePath = serviceURL(param.requestType);
    return axios({
      url: servicePath,
      method: "POST",
      data: param.data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
