import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

export const bookingnotificationService = async (payload) => {
  let serviceType = RestConfig.find((data) => data.type === "BOOKINGNOTIFICATION")
    .endpoint;

  let request = {
    requestType: "sendbookingunsuccessful",
    data: {
      type: serviceType,
      requestParam: {
       saleId:payload.saleId,
       emailAddress: payload.emailAddress
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
