import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import { bookingDetailsReducer } from "./bookingDetailsReducer";
import { bookingReducer } from "./bookingReducer";

export default combineReducers({
  appDetails: appReducer,//not actively used
  bookingDetails: bookingReducer,//not actively used
  bookingDetailsV2: bookingDetailsReducer,//in use for book journey
});
