import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import '../node_modules/slick-carousel/slick/slick.css';
import './assets/app.css';
import PageMetaData from "./library/baaShared/PageMetaData";
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/



const Layout = ({ route }) => (
  <React.Fragment>
    {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
    <PageMetaData route={route}/>

    {/* <div className={"Page-layout"+ (route.fields.Title.value !== "Home" ? ' not-homepage':' homepage')}>
    <Placeholder name="hcjss-header" rendering={route} />
	  <Placeholder name="hcjss-main" rendering={route} />
	  <Placeholder name="hcjss-footer" rendering={route} />
    </div> */}

    <div>
      <Placeholder name="Header" rendering={route} pageData={route}/>
      <Placeholder name="HeroBanner" rendering={route} pageData={route}/>
      <Placeholder name="Main" rendering={route} pageData={route} />
      <Placeholder name="Footer" rendering={route} />
    </div>
  </React.Fragment>
);

export default Layout;
