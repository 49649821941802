/* Rendered when site is down for maintenance.
   To render this component set UNDER_MAINTENANCE="true" in constants.js file.
   Also, update the time (in line number 14) when the website would possibly be live again.
*/
import React from 'react';
import './Maintenance.scss';
import logo from "../../../assets/IBAA_Tour_Logo_Black_CMYK.png"

function Maintenance() {
    return <div className="maintain-container">
        <div className="maintenance-image"><img src={logo} className="png-logo"
            alt="Maintenance" /></div>
        <div className="maintenance-message">
            <h1>
                Our website is currently down for maintenance and should be live again at approximately Hrs GMT.
                 Should you wish to make a reservation, please contact <a href="tel:800 467 433">800 467 433</a>. Thank you.
             </h1>
        </div>
    </div>
}
export default Maintenance;
