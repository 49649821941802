import * as Action from "./bookingActions";
const initialState = {
  bookingStep: 0,
  res_availableProducts: [],
  childenhancement: [],
  res_selectedAddon: {},
  selected_tourName: "",
  adults_num: 0,
  family_num: 0,
  display: "",
  children_num: 0,
  infants_num: 0,
  time: "",
  addonName: "",
  user: {},
  selected_addonID: "",
  price: "",
  subtotal: "",
  res_productList: [],
  res_accountDetails: {},
  res_couponToCart: {},
  payment: {},
  confirmation_number: "",
  res_postTransaction: {},
  before_res_postTransaction: {},
  pnr_deletion: {},
  get_prices: {},
  res_retrieveBooking: {},
  account_form: [],
  billing_form: {},
  ni_order_status: {},
  saleId: "",
  tax: "",
  additional_visitors: [],
  slot_details: {},
  adults_form: [],
  children_form: [],
  family_form: [],
  booking_sitecore: {},
  adult_addon: "",
  addon_price: 0,
  child_addon: 0,
  dubai_addon: 0,
  family_addon: 0,
  UPDATED_CART: [],
  addon_selection: 0,
  shopID: "",
  experience_addon: []
};
const bookingReducer = (state = initialState, action = {}) => {
  if (action.type === Action.FAMILY_SELECT) {
    return {
      ...state,
      family_select: action.payload
    }
  }
  if (action.type === Action.CHILD_SELECT) {
    return {
      ...state,
      child_select: action.payload
    }
  }
  if (action.type === Action.COCKTAIL_SELECT) {
    return {
      ...state,
      cocktail_select: action.payload
    }
  }

  if (action.type === Action.DUBAI_SELECT) {
    return {
      ...state,
      dubai_select: action.payload
    }
  }
  if (action.type === Action.ADDON_SELECT) {
    return {
      ...state,
      addon_selection: action.payload
    }
  } if (action.type === Action.SHOPCART_ID) {
    return {
      ...state,
      shopID: action.payload
    }
  }
  if (action.type === Action.GET_ADDONS) {
    return {
      ...state,
      addonName: action.payload
    }
  }
  if (action.type === Action.DISPLAY) {
    return {
      ...state,
      display: action.payload
    }
  }
  if (action.type === Action.BOOKING_STEP) {
    return {
      ...state,
      bookingStep: action.payload,
    };
  }
  if (action.type === Action.AVAILABLE_PRODUCTS) {
    return {
      ...state,
      res_availableProducts: action.payload,
    };
  }
  if (action.type === Action.ENHANCEMENT) {
    return {
      ...state,
      res_selectedAddon: action.payload.status === 200 ? action.payload : state.res_selectedAddon
    }
  }
  if (action.type === Action.SELECTED_TOURNAME) {
    return {
      ...state,
      selected_tourName: action.payload
    };
  }
  if (action.type === Action.FAMILY_NUM) {
    return {
      ...state,
      family_num: action.payload,
    }
  }
  if (action.type === Action.ADULTS_NUM) {
    return {
      ...state,
      adults_num: action.payload,
    };
  }
  if (action.type === Action.CHILDREN_NUM) {
    return {
      ...state,
      children_num: action.payload,
    };
  }
  if (action.type === Action.INFANTS_NUM) {
    return {
      ...state,
      infants_num: action.payload,
    };
  }
  if (action.type === Action.TIME) {
    return {
      ...state,
      time: action.payload,
    };
  }
  if (action.type === Action.DATE) {
    return {
      ...state,
      date: action.payload,
    };
  }
  if (action.type === Action.UPDATE_USER_DATA) {
    return {
      ...state,
      user: action.user
    }
  }
  if (action.type === Action.ADDONID) {
    return {
      ...state,
      selected_addonID: action.payload,
    };
  }
  if (action.type === Action.PRICE) {
    return {
      ...state,
      price: action.payload,
    };
  }
  if (action.type === Action.SUBTOTAL) {
    return {
      ...state,
      subtotal: action.payload
    }
  }
  if (action.type === Action.PRODUCT_LIST) {
    return {
      ...state,
      res_productList: action.payload,
    };
  }
  if (action.type === Action.ACCOUNT_DETAILS) {
    return {
      ...state,
      res_accountDetails: action.payload,
    };
  }
  if (action.type === Action.COUPONTOCART) {
    return {
      ...state,
      res_couponToCart: action.payload,
    };
  }
  if (action.type === Action.UPDATE_PAYMENT_STATUS) {
    return {
      ...state,
      payment: action.payment
    }
  }
  if (action.type === Action.CONFIRMATION_NUMBER) {
    return {
      ...state,
      confirmation_number: action.payload
    }
  }
  if (action.type === Action.POST_TRANSACTION) {
    return {
      ...state,
      res_postTransaction: action.payload
    }
  }
  if (action.type === Action.PNR_DELETION) {
    return {
      ...state,
      pnr_deletion: action.payload
    }
  }
  if (action.type === Action.GET_PRICES) {
    return {
      ...state,
      get_prices: action.payload
    }
  }
  if (action.type === Action.BEFORE_POST_TRANSACTION) {
    return {
      ...state,
      before_res_postTransaction: action.payload
    }
  }
  if (action.type === Action.RETRIEVE_BOOKING) {
    return {
      ...state,
      res_retrieveBooking: action.payload
    }
  }
  if (action.type === Action.RESET_BOOKING) {
    return initialState;
  }
  if (action.type === Action.ACCOUNT_FORM) {
    return {
      ...state,
      account_form: action.payload
    }
  }
  if (action.type === Action.BILLING_FORM) {
    return {
      ...state,
      billing_form: action.payload
    }
  }
  if (action.type === Action.NI_ORDER_STATUS) {
    return {
      ...state,
      ni_order_status: action.payload
    }
  } if (action.type === Action.SALE_ID) {
    return {
      ...state,
      saleId: action.payload
    }
  } if (action.type === Action.TAX) {
    return {
      ...state,
      tax: action.payload
    }
  } if (action.type === Action.ADDITIONAL_VISITORS) {
    return {
      ...state,
      additional_visitors: action.payload
    }
  } if (action.type === Action.SLOT_DETAILS) {
    return {
      ...state,
      slot_details: action.payload
    }
  } if (action.type === Action.ADULTS_FORM) {
    return {
      ...state,
      adults_form: action.payload
    }
  } if (action.type === Action.CHILDREN_FORM) {
    return {
      ...state,
      children_form: action.payload
    }
  } if (action.type === Action.FAMILY_FORM) {
    return {
      ...state,
      family_form: action.payload
    }
  }
  if (action.type === Action.BOOKING_SITECORE) {
    return {
      ...state,
      booking_sitecore: action.payload,
    };
  } if (action.type === Action.EXPERIENCE_ADDON) {
    return {
      ...state, experience_addon: action.payload,
    };
  } if (action.type === Action.ADULT_ADDON) {
    return { ...state, adult_addon: action.payload, experience_addon: [...state.experience_addon, action.payload] }
  }
  if (action.type === Action.CHILD_ADDON) {
    return {
      ...state, child_addon: action.payload, experience_addon: [...state.experience_addon, action.payload]
    }
  }
  if (action.type === Action.DUBAI_ADDON) {
    return {
      ...state, dubai_addon: action.payload, experience_addon: [...state.experience_addon, action.payload]
    }
  }
  if (action.type === Action.FAMILY_ADDON) {
    return {
      ...state, family_addon: action.payload, experience_addon: [...state.experience_addon, action.payload]
    }
  }
  if (action.type === Action.ADDON_PRICE) {
    return {
      ...state,
      addon_price: action.payload
    }
  }
  if (action.type === Action.CHILD_ENHANCEMENT) {
    return {
      ...state,
      childenhancement: action.payload
    }
  } if (action.type === Action.ALL_PRICE) {
    return {
      ...state,
      allprice: action.payload
    }
  } if (action.type === Action.RESET_ADDON) {
    return {
      ...state,
      res_selectedAddon: {},
      experience_addon: [],
      adult_addon: "",
      child_addon: "",
      family_addon: "",
      dubai_addon: "",
      price: ""
    }
  } else { return state; }
};
export { bookingReducer }
export default bookingReducer;
