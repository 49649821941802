import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";
import { countryCodeAlphaCodes } from "./countryCodes";
let restService = new RestService();

export const accountService = async ({
  mainGuestDetails,
  cartBase,
  giftGuestDetails,
}) => {
  let serviceType = RestConfig.find((data) => data.type === "ACCOUNT").endpoint;

  let request = {
    requestType: "postAccount",
    data: {
      type: serviceType,
      requestParam: {
        accountDetailsList: [
          {
            lastName: mainGuestDetails.lastName.value,
            firstName: mainGuestDetails.firstName.value,
            phoneNumber: mainGuestDetails.phone.value,
            emailAddress: mainGuestDetails.email.value,
            title: mainGuestDetails.title.value,
            countryCode:mainGuestDetails.country.code,
            country: countryCodeAlphaCodes[
              mainGuestDetails.countryCode.code
            ]
              ? countryCodeAlphaCodes[mainGuestDetails.countryCode.code]
              : "UAE",
            accountType: "Main",
          },
        ],
        items: cartBase.items,
        shopCartId: cartBase.shopCartId,
        subTotal: cartBase.subTotal,
        totalTax: 0,
        totalAmount: cartBase.totalAmount,
        visitorsDetailsList: [],
      },
    },
  };

  //if it is a gift send gift account details also to account service
  if (mainGuestDetails.isGift) {
    request.data.requestParam.accountDetailsList.push({
      lastName: giftGuestDetails.lastName.value,
      firstName: giftGuestDetails.firstName.value,
      phoneNumber: giftGuestDetails.phone.value,
      emailAddress: giftGuestDetails.email.value,
      title: giftGuestDetails.title.value,
      accountType: "Gift",
      countryCode: countryCodeAlphaCodes[giftGuestDetails.countryCode.code]
        ? countryCodeAlphaCodes[giftGuestDetails.countryCode.code]
        : "UAE",
    });
  }

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let accountsData = {};
        if (res.status === 200 && res.data.data) {
          accountsData = {
            shopCartId: res.data.data.shopCartId,
            mainId: res.data.data.mainId,
            subTotal: res.data.data.subTotal,
            totalAmount: res.data.data.totalAmount,
            totalTax: res.data.data.totalTax,
          };
        }

        resolve(accountsData);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
